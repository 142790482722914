/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import Header from './header';
import './layout.css';

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query   TitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} TailwindChange="bg-purple-600"/>
          <main>{children}</main>
          <footer className="w-full bg-gray-700">
            <div className="container">
              <p className="text-gray-500">&copy; 2019 DailyCoach.co</p>
            </div>
          </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
