import React from 'react';
import Layout from '../../components/articlelayout';
import SEO from '../../components/seo';
import {graphql} from 'gatsby';

const Article = ({data}) => {
  const frontmatter = data.markdown.frontmatter;
  const body = data.markdown.html;

  return (
    <Layout>
      <SEO title="Why-How Laddering For Personal Goals" />
      <div
        className="block bg-purple-100 py-10 px-2">
        <div className="container max-w-5xl">
          <div className="flex">
            <h1 className="text-gray-700 mt-0 lg:text-4xl text-center lg:text-left">{frontmatter.title}</h1>
          </div>        
        </div>

      </div>
      <div className="container max-w-5xl">
        <div className="text-gray-600 mx-2">
          <p className="my-0">By: {frontmatter.author}</p>
          <p className="my-0">{frontmatter.date}</p>
        </div>
        <div
          className="article-body text-gray-600 leading-loose text-lg mx-2 mt-10  "
          dangerouslySetInnerHTML={{__html: body}} />
      </div>
    </Layout>
  );
};

export default Article;

export const pageQuery = graphql`
  query{
    markdown: markdownRemark(frontmatter: {path:{eq: "/articles/whyhowladdering"}}) {
      frontmatter {
        title
        path
        date(formatString: "MMMM D, YYYY")
        templateKey
        author
        image } 
      html
    }
  }
  `;
